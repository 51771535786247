import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdService {
  constructor(private http: HttpClient) {}

  boost(idAd: any): Observable<any> {
    return this.http.get(
      environment.apiURL + `/publication/boost/` + idAd
    ) as Observable<any>;
  }

  runGedeonTest(): Observable<any[]> {
    return this.http.get(
      environment.apiURL + `/publication/gedeon/` + 'test'
    ) as Observable<any[]>;
  }
  runGedeon(): Observable<any[]> {
    return this.http.get(
      environment.apiURL + `/publication/gedeon/` + 'prod'
    ) as Observable<any[]>;
  }

  getAds(): Observable<any[]> {
    return this.http.get(environment.apiURL + `/publication`) as Observable<
      any[]
    >;
  }

  getAll(
    page: number,
    limite: number,
    workflowState: number
  ): Observable<any[]> {
    return this.http.get(
      environment.apiURL +
        `/publication/getAll/` +
        page +
        '/' +
        limite +
        '/' +
        workflowState
    ) as Observable<any[]>;
  }

  search(input: string, page: number, limite: number): Observable<any[]> {
    return this.http.get(
      environment.apiURL +
        `/publication/search/` +
        input +
        '/' +
        page +
        '/' +
        limite +
        '/'
    ) as Observable<any[]>;
  }

  createAd(ad: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/publication`,
      ad
    ) as Observable<any>;
  }
  renewAd(idAd: any): Observable<any> {
    return this.http.get(
      environment.apiURL + `/publication/renew/` + idAd
    ) as Observable<any>;
  }
  saveNumBill(saveNumBill: any): Observable<any[]> {
    return this.http.post(
      environment.apiURL + `/publication/bill/reserveNum`,
      saveNumBill
    ) as Observable<any>;
  }

  updateAdAdmin(ad: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/publication/update/admin`,
      ad
    ) as Observable<any>;
  }

  updateAd(ad: any): Observable<any> {
    return this.http.post(
      environment.apiURL + `/publication/update`,
      ad
    ) as Observable<any>;
  }
  updateAdAttr(attr: any, value: any, id: any): Observable<any> {
    console.log(attr, value, id);
    return this.http.post(environment.apiURL + `/publication/update/attr`, {
      attr,
      value,
      id,
    }) as Observable<any>;
  }
  getAd(idAd: any): Observable<any> {
    return this.http.get(
      environment.apiURL + `/publication/` + idAd
    ) as Observable<any>;
  }

  getAdsByUser(idUser: any): Observable<any[]> {
    return this.http.get(
      environment.apiURL + `/publication/user/` + idUser
    ) as Observable<any[]>;
  }

  /*   getAdNotPaidByUser(idUser: any): Observable<any> {
    return this.http.get(
      environment.apiURL + `/publication/user/notpaid/` + idUser
    ) as Observable<any>;
  } */
  getForfaitByUser(idUser: any): Observable<any> {
    return this.http.get(
      environment.apiURL + `/publication/forfait/user/` + idUser
    ) as Observable<any>;
  }
  upload(formData: any): Observable<any> {
    return this.http.post(environment.apiURL + `/upload`, formData, {
      reportProgress: true,
      observe: 'events',
    }) as Observable<any>;
  }
  getPhotoByUser(idUser: any): Observable<any> {
    return this.http.get(
      environment.apiURL + `/publication/photos/user/` + idUser
    ) as Observable<any>;
  }
  getPhotoByAd(idAd: any): Observable<any> {
    return this.http.get(
      environment.apiURL + `/publication/photos/` + idAd
    ) as Observable<any>;
  }
  getBillByAd(idAd: any): Observable<any> {
    return this.http.get(
      environment.apiURL + `/publication/bill/` + idAd
    ) as Observable<any>;
  }
  getFileByUser(idUser: any): Observable<any> {
    return this.http.get(
      environment.apiURL + `/publication/files/user/` + idUser
    ) as Observable<any>;
  }
  getContratsByUser(idUser: any): Observable<any> {
    return this.http.get(
      environment.apiURL + `/publication/contrats/user/` + idUser
    ) as Observable<any>;
  }
  getLettresByUser(idUser: any): Observable<any> {
    return this.http.get(
      environment.apiURL + `/publication/lettres/user/` + idUser
    ) as Observable<any>;
  }

  saveContrat(base64: any, idAd: any) {
    return this.http.post(environment.apiURL + `/publication/save-contrat/`, {
      contrat: base64,
      idAd,
    }) as Observable<any>;
  }

  saveBill(base64: any, idAd: any, numBill: any) {
    return this.http.post(environment.apiURL + `/publication/save-bill/`, {
      contrat: base64,
      idAd,
      numBill,
    }) as Observable<any>;
  }

  saveBillStripe(dataStripe: any) {
    return this.http.post(
      environment.apiURL + `/publication/save-bill-stripe/`,
      {
        dataStripe: dataStripe,
      }
    ) as Observable<any>;
  }

  setOnline(idAd: any, bool: any) {
    return this.http.post(environment.apiURL + `/publication/online/`, {
      online: bool,
      ad: idAd,
    }) as Observable<any>;
  }

  getAllAdStatus(): Observable<any> {
    return this.http.get(
      environment.apiURL + `/publication/status/`
    ) as Observable<any>;
  }
}
